<template>
  <div class="download">
    <div class="download_url">
      <img class="qrcode" src="../../static/image/qrcode.png" width="240px" />
      <a
        href="https://shituopub.oss-cn-hangzhou.aliyuncs.com/v1.1.0.zip"
        target="_blank"
      >
        <img
          class="qrcode"
          src="../../static/image/mobile/m_downloadBtn.png"
          width="240px"
        />
      </a>
    </div>
  </div>
</template>
<script>
export default {
  name: "download",
  data() {
    return {
      showCode: false,
    };
  },
  computed: {},
  mounted() {
    window.scroll(0, 0);
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.download {
  padding: 0 0 100px 0;
  margin: 0 auto;
  text-align: center;
  background-color: #fbfbfb;
  .download_tips {
    font-size: 24px;
    font-weight: bold;
    color: #1d1b19;
    line-height: 33px;
    letter-spacing: 1px;
  }
  .download_url {
    margin-top: 60px;
    img {
      margin: 0 20px;
      cursor: pointer;
    }
    .qrcode {
      cursor: pointer;
      vertical-align: top;
      margin-top: 21px;
    }
  }
}
</style>
